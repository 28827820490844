import React, { useState } from "react";
import { Modal, Button, Form, Table } from "react-bootstrap";
import { FaEdit } from "react-icons/fa";
import { useFetchServices } from "../../hooks/useFetchService";
import axios from "axios";
import { toast } from "react-hot-toast";

const ServicesInAdminDashboard = () => {
  // services
  const { services, setIsStateChange } = useFetchServices();

  // states to use in modal
  const [showEditModal, setShowEditModal] = useState(false);
  const [editedService, setEditedService] = useState({});
  const [editedServiceName, setEditedServiceName] = useState("");
  const [editedServiceDescription, setEditedServiceDescription] = useState("");

  // handle modal
  const openEditModal = (service) => {
    setEditedService(service);
    setEditedServiceName(service.service_name);
    setEditedServiceDescription(service.service_desc);
    setShowEditModal(true);
  };

  const handleUpdateService = async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/services/${editedService.service_code}`,
        {
          service_name: editedServiceName,
          service_desc: editedServiceDescription,
        }
      );

      if (response?.data?.status) {
        setIsStateChange(true);
        toast.success(response.data.message, {
          style: {
            borderRadius: "10px",
            background: "#222",
            color: "#fff",
          },
        });
        // Close the modal
        setShowEditModal(false);
        // Update the services list or re-fetch it
      } else {
        toast.error("Failed to update service");
      }
    } catch (error) {
      toast.error("Failed to update service");
    }
  };

  return (
    <div className="my-12">
      <h3 className="mt-5">Services</h3>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Service Code</th>
            <th>Service Name</th>
            <th>Service Description</th>
            <th>Settings</th>
          </tr>
        </thead>
        <tbody>
          {services.map((service) => (
            <tr key={service.service_code}>
              <td>{service.service_code}</td>
              <td>{service.service_name}</td>
              <td>{service.service_desc}</td>
              <td>
                <Button
                  variant="primary"
                  onClick={() => openEditModal(service)}
                >
                  <FaEdit /> Edit
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Edit Service Modal */}
      <Modal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        style={{ marginTop: "150px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Service</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="editedServiceName">
              <Form.Label>Service Name</Form.Label>
              <Form.Control
                type="text"
                value={editedServiceName}
                onChange={(e) => setEditedServiceName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="editedServiceDescription">
              <Form.Label>Service Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={6}
                value={editedServiceDescription}
                onChange={(e) => setEditedServiceDescription(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleUpdateService}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ServicesInAdminDashboard;
