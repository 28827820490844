import { useState, useEffect } from "react";
import axios from "axios";

// Custom hook for fetching services data
export function useFetchServices() {
  const [services, setServices] = useState([]);
  const [isServicesLoading, setIsServicesLoading] = useState(false);

  // declared state to refetch service after edit
  const [isStateChange, setIsStateChange] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsServicesLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/services`
        );
        setServices(response?.data?.results);
        setIsServicesLoading(false);
      } catch (error) {
        console.log(error);
        setIsServicesLoading(false);
      }
    };

    fetchData();
  }, [isStateChange]);

  return { services, isServicesLoading, setIsStateChange };
}
